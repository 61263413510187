import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { parseQueries } from "app/utils/routerUtils";
import { HTTP_STATUS_CODES } from "app/constants";
import { authSuccess, changePassword, logout, verifyToken } from "app/pages/Auth/AuthActions";
import ChangePassword from "app/pages/Auth/ChangePassword/ChangePassword.jsx";
import { useCallback, useEffect, useState } from "react";
import { mapErrorHttpCodeToMessage } from "app/utils/utils";

const ChangePasswordContainer = ({
	changePassword = () => {},
	authSuccess = () => {},
	logout = () => {},
	verifyToken = () => {},
}) => {
	const [verifying, setVerifying] = useState(false);
	const [isTokenValid, setIsTokenValid] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();
	const query = parseQueries(location.search);
	const authorization = query.token || false;

	useEffect(() => {
		if (authorization) {
			setVerifying(true);
			verifyToken(query.token).then(res => {
				if (res?.status !== HTTP_STATUS_CODES.OK) {
					setIsTokenValid(false);
				}
				setVerifying(false);
			});
		}
	}, [authorization]);

	const handleSubmit = useCallback(
		(values, { setSubmitting, setErrors, setStatus }) => {
			if (!authorization) {
				setSubmitting(false);
				setStatus({ success: false });
				setErrors({ _error: { id: "error.must.be.authenticated" } });
			} else {
				changePassword(
					{
						password: values.password,
					},
					query.token
				).then(res => {
					setSubmitting(false);
					if (res?.status === HTTP_STATUS_CODES.OK) {
						setStatus({ success: true });
						authSuccess(res, authorization);
						navigate({
							pathname: "/listing",
						});
					} else {
						if (res?.status === HTTP_STATUS_CODES.FORBIDDEN) {
							logout();
							navigate({ pathname: "/home/login" }, { replace: true });
						} else {
							setErrors({
								_error: mapErrorHttpCodeToMessage(res.status),
							});
						}
						setStatus({ success: false });
					}
				});
			}
		},
		[authorization]
	);

	return (
		<ChangePassword
			handleSubmit={handleSubmit}
			isTokenValid={isTokenValid}
			verifying={verifying}
		/>
	);
};

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			changePassword,
			authSuccess,
			logout,
			verifyToken,
		},
		dispatch
	),
});

ChangePasswordContainer.propTypes = {
	changePassword: PropTypes.func,
	authSuccess: PropTypes.func,
	logout: PropTypes.func,
	verifyToken: PropTypes.func,
};

export default connect(
	null,
	mapDispatchToProps
)(ChangePasswordContainer);

import { ref, object, string } from "yup";
import { PASSWORD_POLICY_REGEX, PASSWORD_LIMIT_CHARS } from "app/constants";

export const validationSchemaChangePassword = object().shape({
	password: string()
		.min(PASSWORD_LIMIT_CHARS, { id: "auth.password.policy.not.valid" })
		.required({ id: "error.new.password.required" })
		.test(
			"atLeastOneUppercase",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.UPPERCASE.test(value)
		)
		.test(
			"atLeastOneDigit",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.DIGIT.test(value)
		)
		.test(
			"atLeastOneSpecialChar",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.SPECIAL_CHAR.test(value)
		),
	confirmPassword: string()
		.oneOf([ref("password"), null], { id: "error.password.do.not.match" })
		.required({ id: "error.new.confirmed.password.required" }),
});

export const validationSchema = object().shape({
	actualPassword: string().required({ id: "error.current.password.required" }),
	changePassword: string()
		.min(PASSWORD_LIMIT_CHARS, { id: "auth.password.policy.not.valid" })
		.required({ id: "error.new.password.required" })
		.test(
			"atLeastOneUppercase",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.UPPERCASE.test(value)
		)
		.test(
			"atLeastOneDigit",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.DIGIT.test(value)
		)
		.test(
			"atLeastOneSpecialChar",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.SPECIAL_CHAR.test(value)
		),
	changeConfirmPassword: string()
		.oneOf([ref("changePassword"), null], { id: "error.password.do.not.match" })
		.required({ id: "error.new.confirmed.password.required" }),
});

import { useCallback } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "app/pages/.shared/form/Button";
import { getParentPath } from "app/utils/routerUtils";
import InputFormik from "app/pages/.shared/form/InputFormik";
import { validationSchemaChangePassword } from "app/pages/Auth/ChangePassword/ChangePasswordSchema";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { ALERT_TYPE } from "app/constants";
import PasswordPolicyValidation from "app/pages/Auth/Signup/PasswordPolicyValidation";
import useShowPassword from "app/utils/hooks/useShowPassword";
import Loader from "app/pages/.shared/Loader";
import RelativeLink from "app/pages/.shared/RelativeLink";
import "../auth.scss";
import className from "classnames";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const ChangePassword = ({ handleSubmit = () => {}, isTokenValid = false, verifying = false }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [iconChangePassword, showChangePassword] = useShowPassword();
	const [iconConfirmPassword, showConfirmPassword] = useShowPassword();

	const handleCancel = useCallback(() => {
		const parentPath = getParentPath(location.pathname);
		navigate({ pathname: `${parentPath}/login` });
	}, [location.pathname]);

	return (
		<div
			className={className({
				auth: true,
				"auth--verifying": verifying,
			})}
		>
			<div className="auth__main">
				<Formik
					initialValues={{
						password: "",
						confirmPassword: "",
					}}
					validationSchema={validationSchemaChangePassword}
					onSubmit={handleSubmit}
					validateOnChange={false}
					validateOnBlur={false}
				>
					{({
						values = {},
						status = {},
						errors = {},
						setStatus,
						isSubmitting,
						submitCount,
					}) => (
						<Form
							className="auth__form"
							onChange={() => {
								setStatus({});
							}}
						>
							<Typography
								variant={TYPOGRAPHY_VARIANTS.REGULAR}
								isBold
								className="auth__headline"
							>
								<FormattedMessage id="auth.change.password.title" />
							</Typography>

							<div className="auth__intro">
								<FormattedMessage id="auth.changer.password.intro" />
							</div>

							<FormErrorMessages
								errors={errors}
								isSubmitting={isSubmitting}
								submitCount={submitCount}
							/>

							<InputFormik
								type={showChangePassword ? "text" : "password"}
								label={<FormattedMessage id="auth.change.new.password.label" />}
								name="password"
								id="auth-password"
								data-testid="password-input"
								className="auth__password"
								note={iconChangePassword}
								disabled={!isTokenValid || verifying}
							/>

							<InputFormik
								type={showConfirmPassword ? "text" : "password"}
								label={<FormattedMessage id="auth.confirm.password.label" />}
								name="confirmPassword"
								id="auth-confirm-password"
								data-testid="confirm-password-input"
								className="auth__password"
								note={iconConfirmPassword}
								disabled={!isTokenValid || verifying}
							/>
							<PasswordPolicyValidation value={values.password} />
							<div className="auth__action auth__resetpassword__action">
								{status?.success === false &&
									errors._error &&
									errors._error.id &&
									isTokenValid && (
										<AlertMessage
											message={
												<FormattedMessage
													id={errors._error.id}
													{...errors._error.values && {
														values: errors._error.values,
													}}
												/>
											}
											alertType={ALERT_TYPE.ERROR}
										/>
									)}
								{!isTokenValid && (
									<AlertMessage
										message={
											<FormattedMessage
												id="error.token.expired.alert"
												values={{
													url: (
														<RelativeLink
															to={{ pathname: "/home/resetpassword" }}
														>
															<FormattedMessage
																id={
																	"error.token.expired.link.label"
																}
															/>
														</RelativeLink>
													),
												}}
											/>
										}
										alertType={ALERT_TYPE.ERROR}
									/>
								)}
								<Button
									className="auth__button"
									submit
									variant="primary"
									loading={isSubmitting}
									data-testid="change-password-button"
									disabled={!isTokenValid || verifying}
								>
									<FormattedMessage id="auth.change.password.button.label" />
								</Button>
								<Button
									variant="secondary"
									onClick={handleCancel}
									data-testid="cancel-button"
									disabled={!isTokenValid || verifying}
								>
									<FormattedMessage id="cancel.action" />
								</Button>
								{status?.success && (
									<div className="profile__password__success-message">
										<AlertMessage
											message={
												<FormattedMessage id="general.success.button.label" />
											}
											alertType={ALERT_TYPE.SUCCESS}
										/>
									</div>
								)}
							</div>
						</Form>
					)}
				</Formik>
			</div>
			{verifying && (
				<div className="auth__loader" data-testid="auth-loader">
					<Loader />
					<FormattedMessage id="auth.change.password.verifying.token" tagName="div" />
				</div>
			)}
		</div>
	);
};

ChangePassword.propTypes = {
	handleSubmit: PropTypes.func,
	isTokenValid: PropTypes.bool,
	verifying: PropTypes.bool,
};

export default ChangePassword;

import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import Button from "app/pages/.shared/form/Button";
import { Form, Formik } from "formik";
import "../auth.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { getParentPath } from "app/utils/routerUtils";
import InputFormik from "app/pages/.shared/form/InputFormik";
import { object, string } from "yup";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { ALERT_TYPE } from "app/constants";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const ResetPassword = ({ handleSubmit, initialValues = {} }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const handleCancelButtonClick = useCallback(() => {
		const parentPath = getParentPath(pathname);
		navigate({ pathname: `${parentPath}/login` });
	}, [pathname]);

	return (
		<div className="auth auth__resetpassword">
			<div className="auth__main">
				<Typography variant={TYPOGRAPHY_VARIANTS.LARGE} isBold className="auth__headline">
					<FormattedMessage id="auth.reset.password.title" />
				</Typography>

				<div className="auth__intro">
					<FormattedMessage id="auth.reset.password.intro" />
				</div>

				<Formik
					enableReinitialize
					initialValues={{
						email: "",
						...initialValues,
					}}
					validationSchema={object({
						email: string()
							.required({
								id: "error.email.required",
							})
							.email(() => ({
								id: "error.email.format",
							})),
					})}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={handleSubmit}
				>
					{({ errors = {}, isSubmitting, status = {}, setStatus, submitCount }) => (
						<Form
							noValidate
							className="auth__form"
							onChange={() => {
								// reset status to avoid success message to be dispayed with errors messages after a first successful submit
								setStatus({});
							}}
						>
							<FormErrorMessages
								errors={errors}
								isSubmitting={isSubmitting}
								submitCount={submitCount}
							/>

							<InputFormik
								type="email"
								name="email"
								id="email"
								isRequired={true}
								label={<FormattedMessage id="auth.email.label" />}
								data-testid="email-input"
							/>

							<div className="auth__action auth__resetpassword__action">
								{errors._error && (
									<AlertMessage
										alertType={ALERT_TYPE.ERROR}
										message={<FormattedMessage id={errors._error.id} />}
									/>
								)}

								<Button
									className="auth__button"
									submit
									variant="primary"
									loading={isSubmitting}
									data-testid="reset-password-button"
								>
									<FormattedMessage id="auth.reset.pasword.button.label" />
								</Button>
								{status.success && !isSubmitting && (
									<AlertMessage
										message={
											<FormattedMessage id="auth.reset.password.success.label" />
										}
										alertType={ALERT_TYPE.SUCCESS}
									/>
								)}
								{!status.success && (
									<Button
										variant="secondary"
										onClick={handleCancelButtonClick}
										data-testid="cancel-button"
									>
										<FormattedMessage id="cancel.action" />
									</Button>
								)}
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

ResetPassword.propTypes = {
	handleSubmit: PropTypes.func,
	initialValues: PropTypes.object,
};

export default memo(ResetPassword);
